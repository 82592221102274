.App {
  position: absolute;
  
  background: #1D193A;
  height:100%;
  width: 100%;

}
/* General Website Style rules */


a { 
  margin: 10px;
  text-decoration: none;
  color: rgb(0, 0, 0);

}


.backColor{
  background: #1D193A;

}

body{
  background: #1D193A;
  width: 100%;
  height: 100vh;
  
  
.container {
  position: absolute;
  background: #1D193A;
  height:100%;
  width: 100%;
}

}

html, body, body div, span, object, iframe, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
  margin: 0;

  font-size: 100%;
  
  font-family: Lora;
  scroll-behavior: smooth;
  
}