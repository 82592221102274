.page__wrapper {

    width: 100%;
    
    margin: 0;
    padding: 0;
    

}

.id_home{
    display: block;
    position: relative;
    top: -8.333333333333334vw;
    visibility: hidden;
}

.home__wrapper {
   
    
    width: 100%;
    height: 100%;

}

h1,
h2 {
    font-size: 40px;
    margin: 0;
    padding: 0;
    font-family: "Space Grotesk";
    color: #F3F0E8;
}

h3 {
    font-size: 3.6458333333333335vw;
    margin: 0;
    padding: 0;
    color: #F3F0E8;
    font-family: 'Gill Sans';
    font-style: oblique;
}

h4 {

    font-size: 2.0833333333333335vw;
    margin: 0;
    padding: 0;

    color: #F3F0E8;
    font-family: 'Gill Sans';
    font-style: oblique;
}



.title__container {

    display: flex;
    
}
.title__container a{
    margin: 0;
}

.text__container {
    margin-top: 10.416666666666666vw;
    margin-left: 5.208333333333333vw;
    font-size: 8vw;
    width: 50%;
}
.text__container  h1{
    margin: 0;
    padding: 0;
    
    font-size: 70px;
    margin-bottom: 2.6041666666666665vw;
}

.text__container  h3{
    margin: 0;
    padding: 0;
    font-size: 50px;
    margin-bottom: 2.6041666666666665vw;
}


.img__container {
    margin-top: 7.8125vw;
    width: 50%;
}

.about__container {

    width: 100%;
    margin-top: 3.6458333333333335vw;


}
.about__container h4{
    font-size: 40px;
}

.about__header {
    height: 70px;
    display: flex;
    justify-content: center;
    margin: 1.0416666666666667vw;
}

.about__text {

    margin-left: 9.375vw;
    margin-right: 9.375vw;
}
.about__text h4{
    font-size: 40px;
}
.about__text p {
    text-align: center;
    font-size: 19px;
    color: #F3F0E8;
    margin-bottom: 2.6041666666666665vw;
}

.skills__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(5.208333333333333vw, auto);
}

.skills__wrapper_dash {
    display: inline;
    color: #ADFF00;

}

.skills__wrapper h4 {
    text-align: center;
    font-size:  19px;
    margin: 0;
    padding: 0;

    color: #F3F0E8;
    font-family: 'Gill Sans';
    font-style: oblique;
}



.skills__container {
    margin-bottom: 160px;
}


.skills__container ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    color: #F3F0E8;
    font-size: 19px;
}

.under__container {
    display: flex;
    align-items: center;
    justify-content: center;

}

.under__container p {
    

    font-size: 19px;
    color: #F3F0E8;
}
.under__container a {
    margin: 0;
}

.contact__container {
    
    margin-bottom: 8.854166666666666vw;
    
}

.contact__header {
    margin-top: 8.63768115942029vw;
    
    
    height: 70px;
    display: flex;
    justify-content: center;
}
.contact__header h4{
    font-size: 40px;
}
.contact__container h1,h2,h3{
    
    margin-top: 5.208333333333333vw;
    font-size: 20px;
    margin-bottom: 8.854166666666666vw;
    
}

.contact__container__wrapper {
    height: 100%;
    margin-top: 0.5208333333333334vw;
    display: flex;
    width: 100%;
    margin-bottom: 5.208333333333333vw;
}


.contact__left {
    display: flex;
    align-items: center;
    justify-content: center;
    
    height: 34.739583333333336vw;
    width: 46.875vw;
    font-size: 20px;
    color: white;
    
    
    border-bottom: 1px solid #ADFF00;
    border-top: 1px solid #ADFF00;
    border-right: 1px solid #ADFF00;

    margin-right: 15.625vw;

}

.contact__right{
    height: 34.739583333333336vw;
    

}

.contact__right h6{
    margin: 3px;
    font-size: 20px;
    color: white;
}

.mail__icon{
    
}

.href__container{
    
    display: flex;
    align-items: center;
    width: 100%;

}
.href__container a{
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

.href__container p{
    font-size: 20px;
}