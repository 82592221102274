.myInput {
    width: 40.677083333333336vw;
    
    margin: 0.3125vw 0;
    font-size: 20px;
    border-bottom: 2px solid #ADFF00;
    background-color: transparent;
    outline: 0;
    border-width: 0 0 2px;

    color: white;

}


.myInput:focus {
    border-bottom: 2px solid white;
}

.myTextArea {
    height: 13.28125vw;
    width: 40.677083333333336vw;
    margin: 2.6041666666666665vw 0;
   
    
    
    font-size: 20px;
    border: 2px solid #ADFF00;
    background-color: transparent;
    outline: 0;
   

    color: white;
}

.myTextArea:focus{
    border: 2px solid white;
}