.btn {


    padding: 0.5208333333333334vw;
   
    cursor: pointer;
    background: transparent;
    border: 3px solid #ADFF00;
    border-radius: 10px;
    color: #ADFF00;
    box-sizing: border-box;

    font-family: Lora;
    font-size: 20px;

    transition: .6s;

}

.btn:hover {


    color: #F3F0E8;
    border: 3px solid #F3F0E8;

}

.contactBtn {
    
    width: 170px;
    height: 40px;
    background: #ADFF00;
    border-radius: 10px;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    transition: .6s;
    
}

.sendBtn {
    padding: auto;

    background: #ADFF00;
    border-radius: 10px;
    width: 170px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    transition: .6s;
}
.sendBtn:disabled{
    background-color: #F3F0E8;
    cursor: not-allowed;
}

.sendBtn:hover{
    color: #1D193A;
    background-color: #F3F0E8; 
}

.contactBtn:hover{
    color: #1D193A;
    background-color: #F3F0E8;
   
}