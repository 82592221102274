.footer {


    padding: 30px;

    height: 4.6875vw;
    border-top: 2px solid #ADFF00;



}




.icon__footer{
    width: 100%;
    
    text-align: center;
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconT{
    
    cursor: pointer;
    background: url('/src/static/Vector.png');
    height: 2.1875vw;
    width: 2.1875vw;
    display: block;
    
}

.iconM{
    cursor: pointer;

    background: url('/src/static/mail.png');
    height: 1.4583333333333333vw;
    width: 1.9270833333333333vw;
    display: block;
    
}
p{
    
    font-size: 1.0416666666666667vw;
    color: #F3F0E8;
}
.iconG{
    cursor: pointer;
    background: url('/src/static/🦆\ icon\ _github_.png');
    height: 2.1875vw;
    width: 2.1875vw;
    display: block;
    
}

.rigths {
 
    background: url("/src/static/rights.png");
    height: 0.9375vw;
    width: 8.333333333333334vw;
    float: left;
    
}