

.menu {
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 1.9791666666666667vw;
    color: #F3F0E8;
    margin-right: 10.416666666666666vw;

}


.header__block_left {
    
    float: left;
    display: block;
    align-items: center;
}

.header__block_rigth {
   
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    float: right;
    justify-content: right;
    align-items: center;

    
}

.title_name {
    margin-left: 1.5625vw;
    display: flex;
    align-items: center;
    font-family: Lora;
    font-size: 30px;
   
 
    letter-spacing: 0em;
    text-align: left;
    color: #F3F0E8;
    height: 4.479166666666667vw;



}


.navbar__header {
    width: auto;
    position: fixed;
    width: 100%;
    display: flex;
    
    padding: 0;
    
    background-color: #1D193A;

    height: 4.6875vw;
    border-bottom: 2px solid #ADFF00;

}

.header__link {
    margin: 30px 1px;
    font-size: 19px;
    padding: 0 10px;
    transition: 1s;
    cursor: pointer;
    color: #F3F0E8;

}

.header__link_get {
    padding: 0 10px;
    margin: 1px 11px;
    text-decoration: underline #F3F0E8 7px;
    font-size: 19px;
}

.header__link:hover {

    text-decoration: underline #F3F0E8 2px;

}